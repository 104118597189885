import React from 'react';
import Layout from '../components/dfa-theme/layout';
import ContentBlock from '../components/content-block';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Philly, BocaRaton, Social } from '../components/address';
import { CONSTANTS } from '../utils/constants';
import '../scss/pages/contact-us.scss';

const Privacy = () => {
	const metaTags = {
		name: 'Privacy',
		description: 'Deerfield Privacy',
		keywords: 'Deerfield, Privacy',
	};
	return (
		<Layout meta={metaTags}>
			<div className='contact-hero-container'>
				<ContentBlock
					outerBgColor={CONSTANTS.WHITE}
					className='contact-header block'
				>
					<Grid>
						<Row>
							<Col xs={1}></Col>
							<Col xs={10} className='careers-content title-block'>
								<p className='header-orange dark'>contact</p>
								<h1 className='dark'>Thanks for reaching out!</h1>
							</Col>
							<Col xs={1}></Col>
						</Row>
					</Grid>
				</ContentBlock>

				<ContentBlock outerBgColor={CONSTANTS.WHITE} className=' block'>
					<Grid>
						<Row>
							<Col xs={0} md={1}></Col>
							<Col xs={12} md={4} className='contact-content address-block'>
								<Social className='contact-address' icons={false} />
								<Philly className='contact-address' />
							</Col>
							<Col xs={12} md={6} className='thanks-content'>
								<h2 className='blue'>Looking for more? </h2>
								<p>
									Discover what else we can do as your Agency of Brand
									<sup>®</sup>.{' '}
								</p>
								<div className='thanks-buttons'>
									<a href='/culture' className='dfa-button-orange'>
										our story
									</a>
									<a href='/leadership' className='dfa-button-orange'>
										who we are
									</a>
								</div>
							</Col>
							<Col xs={0} md={1}></Col>
						</Row>
					</Grid>
				</ContentBlock>
			</div>
		</Layout>
	);
};
export default Privacy;
